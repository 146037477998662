@font-face {
  font-family: "anatomy-icons";
  src: url('../fonts/anatomy-icons.eot');
  src: url('../fonts/anatomy-icons.eot?#iefix') format('eot'),
  url('../fonts/anatomy-icons.woff2') format('woff2'),
  url('../fonts/anatomy-icons.woff') format('woff'),
  url('../fonts/anatomy-icons.ttf') format('truetype'),
  url('../fonts/anatomy-icons.svg#anatomy-icons') format('svg');
}

@mixin wa-icon-styles {
  font-family: "anatomy-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%wa-icon {
  @include wa-icon-styles;
}

@function wa-icon-char($filename) {
  $char: "";

  @if $filename == android-store {
  $char: "\E001";
}
  @if $filename == apple-logo {
  $char: "\E002";
}
  @if $filename == browser-explorer {
  $char: "\E003";
}
  @if $filename == browser {
  $char: "\E004";
}
  @if $filename == cash {
  $char: "\E005";
}
  @if $filename == change-password {
  $char: "\E006";
}
  @if $filename == cogs {
  $char: "\E007";
}
  @if $filename == devices-2 {
  $char: "\E008";
}
  @if $filename == devices {
  $char: "\E009";
}
  @if $filename == edit {
  $char: "\E00A";
}
  @if $filename == globe {
  $char: "\E00B";
}
  @if $filename == help {
  $char: "\E00C";
}
  @if $filename == history {
  $char: "\E00D";
}
  @if $filename == home {
  $char: "\E00E";
}
  @if $filename == law {
  $char: "\E00F";
}
  @if $filename == logout {
  $char: "\E010";
}
  @if $filename == manage-outline-2 {
  $char: "\E011";
}
  @if $filename == manage-outline {
  $char: "\E012";
}
  @if $filename == manage {
  $char: "\E013";
}
  @if $filename == medal {
  $char: "\E014";
}
  @if $filename == pencil-cog {
  $char: "\E015";
}
  @if $filename == profile {
  $char: "\E016";
}
  @if $filename == secure {
  $char: "\E017";
}
  @if $filename == sessions {
  $char: "\E018";
}
  @if $filename == statement-2 {
  $char: "\E019";
}
  @if $filename == statement-3 {
  $char: "\E01A";
}
  @if $filename == statement-4 {
  $char: "\E01B";
}
  @if $filename == statement-5 {
  $char: "\E01C";
}
  @if $filename == statement {
  $char: "\E01D";
}
  @if $filename == statistic {
  $char: "\E01E";
}
  @if $filename == wallets {
  $char: "\E01F";
}

@return $char;
}

@mixin wa-icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %wa-icon;
  } @else {
    @include wa-icon-styles;
  }
  content: wa-icon-char($filename);
}
}

.wa-icon-android-store {
  @include wa-icon(android-store);
}
.wa-icon-apple-logo {
  @include wa-icon(apple-logo);
}
.wa-icon-browser-explorer {
  @include wa-icon(browser-explorer);
}
.wa-icon-browser {
  @include wa-icon(browser);
}
.wa-icon-cash {
  @include wa-icon(cash);
}
.wa-icon-change-password {
  @include wa-icon(change-password);
}
.wa-icon-cogs {
  @include wa-icon(cogs);
}
.wa-icon-devices-2 {
  @include wa-icon(devices-2);
}
.wa-icon-devices {
  @include wa-icon(devices);
}
.wa-icon-edit {
  @include wa-icon(edit);
}
.wa-icon-globe {
  @include wa-icon(globe);
}
.wa-icon-help {
  @include wa-icon(help);
}
.wa-icon-history {
  @include wa-icon(history);
}
.wa-icon-home {
  @include wa-icon(home);
}
.wa-icon-law {
  @include wa-icon(law);
}
.wa-icon-logout {
  @include wa-icon(logout);
}
.wa-icon-manage-outline-2 {
  @include wa-icon(manage-outline-2);
}
.wa-icon-manage-outline {
  @include wa-icon(manage-outline);
}
.wa-icon-manage {
  @include wa-icon(manage);
}
.wa-icon-medal {
  @include wa-icon(medal);
}
.wa-icon-pencil-cog {
  @include wa-icon(pencil-cog);
}
.wa-icon-profile {
  @include wa-icon(profile);
}
.wa-icon-secure {
  @include wa-icon(secure);
}
.wa-icon-sessions {
  @include wa-icon(sessions);
}
.wa-icon-statement-2 {
  @include wa-icon(statement-2);
}
.wa-icon-statement-3 {
  @include wa-icon(statement-3);
}
.wa-icon-statement-4 {
  @include wa-icon(statement-4);
}
.wa-icon-statement-5 {
  @include wa-icon(statement-5);
}
.wa-icon-statement {
  @include wa-icon(statement);
}
.wa-icon-statistic {
  @include wa-icon(statistic);
}
.wa-icon-wallets {
  @include wa-icon(wallets);
}